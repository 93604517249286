import React from "react";
import { Avatar, Heading, VStack } from "@chakra-ui/react";
import FullScreenSection from "./FullScreenSection";

const greeting = "Hello, I am Jason Lavenant!";
const bio1 = "A frontend developer";
const bio2 = "specialised in React";

// Implement the UI for the LandingSection component according to the instructions.
// Use a combination of Avatar, Heading and VStack components.
const LandingSection = () => (
  <FullScreenSection
    justifyContent="center"
    alignItems="center"
    isDarkBackground
    backgroundColor="#2A4365"
  >

    <VStack spacing={6}>
      <Avatar 
      src="https://i.pravatar.cc/150?img=7" 
      size='2x' 
      name="Jason Lavenant"/>
      
      <Heading as='h4' size='md'>{greeting}</Heading>

      <VStack spacing={6}>
      <Heading as='h1' >{bio1}</Heading>
      <Heading as='h1' >{bio2}</Heading>
      </VStack>

    </VStack>
    
  </FullScreenSection>
);

export default LandingSection;
